import React, { Suspense } from 'react';

import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import { getAuthRouters } from './AuthRoute';
import { routers } from './routers';
import { Loading } from '@/components';
import { useAuth } from '@/hooks';
import NotAuth from '@/pages/403';

export const AppRouter = () => {
  const auth = useAuth();

  const _routers = getAuthRouters({
    routers,
    noAuthElement: (router) => <NotAuth />,
    // render: (element) => (loading ? <Loading /> : element),
    auth: auth.user?.roles || [],
  });

  return (
    <Suspense fallback={<Loading />}>
      <RouterProvider
        router={createBrowserRouter(_routers)}
        // route loader loading
        fallbackElement={<Loading />}
      />
    </Suspense>
  );
};
