import { catchError, concat, filter, map, switchMap } from 'rxjs';

import { appActions } from './appSlice';
import { startLoading, stopLoading } from '../loading';
import { RootEpic } from '../types';
import { IdentityService } from '@/services/IdentityService';
import { UserService } from '@/services/UserService';
import Utils from '@/utils/Utils';

const pingEpic: RootEpic = (action$) => {
  return action$.pipe(
    filter(appActions.ping.match),
    map(() => ({ type: 'PONG' }))
  );
};

const loginRequest$: RootEpic = (action$) => {
  return action$.pipe(
    filter(appActions.loginRequest.match),
    switchMap((action) => {
      const { input, callback } = action.payload;
      return concat(
        [startLoading({ key: 'login' })],
        IdentityService.Post.login(input).pipe(
          switchMap((loginResponse) => {
            if (loginResponse.errorCode) {
              Utils.errorHandling(loginResponse);
              return [stopLoading({ key: 'login' })];
            }
            return [
              appActions.loginSuccess({ loginResponse, loginData: input }),
              stopLoading({ key: 'login' }),
              appActions.loginCallback({ callback }),
            ];
          }),
          catchError((error) => {
            Utils.errorHandling(error);
            return [stopLoading({ key: 'login' })];
          })
        )
      );
    })
  );
};

const getCurrentUserRequest$: RootEpic = (action$) => {
  return action$.pipe(
    filter(appActions.getCurrentUserRequest.match),
    switchMap(() => {
      return concat(
        [startLoading({ key: 'getCurrentUser' })],
        UserService.Get.fetchCurrentUser().pipe(
          switchMap((me) => {
            return [appActions.setCurrentUser(me)];
          }),
          catchError((error) => {
            Utils.errorHandling(error);
            return [];
          })
        ),
        [stopLoading({ key: 'getCurrentUser' })]
      );
    })
  );
};

const getCurrentUserStatusRequest$: RootEpic = (action$) => {
  return action$.pipe(
    filter(appActions.getCurrentUserStatusRequest.match),
    switchMap(() => {
      return concat(
        [startLoading({ key: 'getCurrentUserStatus' })],
        UserService.Get.fetchCurrentUserStatus().pipe(
          switchMap((status) => {
            console.log(status);
            return [];
          }),
          catchError((error) => {
            Utils.errorHandling(error);
            return [];
          })
        ),
        [stopLoading({ key: 'getCurrentUserStatus' })],
      );
    })
  );
};

const getSettings$: RootEpic = (action$) => {
  return action$.pipe(
    filter(appActions.getSettings.match),
    switchMap(() => {
      return concat(
        [startLoading({ key: 'getSettings' })],
        UserService.Get.fetchSettings().pipe(
          switchMap((settings) => {
            console.log(settings);
            return [];
          }),
          catchError((error) => {
            Utils.errorHandling(error);
            return [];
          })
        ),
        [stopLoading({ key: 'getSettings' })],
      );
    })
  );
};

export const appEpics = [pingEpic, loginRequest$, getCurrentUserRequest$, getCurrentUserStatusRequest$, getSettings$];
