import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { Project } from '@/common/define';
import { ProjectList } from '@/datafaker/projectlist';

interface ProjectState {
  projectList: Project[];
  selectedProject: Project | null;
}

const initialState: ProjectState = {
  projectList: ProjectList,
  selectedProject: null,
};

const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    setSelectedProject: (state, action: PayloadAction<Project | null>) => {
      state.selectedProject = action.payload
    },
  },
});

export const projectActions = projectSlice.actions;
export const projectReducer = projectSlice.reducer;
