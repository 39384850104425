import HttpClient from './HttpClient';
import { RequestOptions } from './types';
import { LoginInput } from '@/common/define';
import { getEnvVars } from '@/environment';

const { apiUrl, oAuthConfig } = getEnvVars();

// eslint-disable-next-line
const getLoginData = (inputValues: any) => {
  const formData: any = {
    grant_type: 'password',
    scope: oAuthConfig.scope,
    client_id: oAuthConfig.clientId,
    ...inputValues,
  };

  if (oAuthConfig.clientSecret) formData['client_secret'] = oAuthConfig.clientSecret;

  // prettier-ignore
  return Object.entries(formData).map(([key, value]) => `${key}=${encodeURIComponent(value as string)}`).join('&');
};

class IdentityController {
  public Post = {
    login: (inputValues: LoginInput, options?: RequestOptions) => {
      const loginData = {
        loginName: inputValues.username,
        password: inputValues.password,
      };
      return HttpClient.post(`${apiUrl}/api/account/login`, loginData, options);
    },
  };
}

export const IdentityService = new IdentityController();
