import { lazy } from 'react';

import { AuthRouteObject } from './AuthRoute';

const NotFound = lazy(() => import('@/pages/404'));
const TimelineSection = lazy(() => import('@/pages/Timekeeping/Timekeeping'));

type MetaMenu = {
  name?: string;
  icon?: React.ReactNode;
};

export type MetaMenuAuthRouteObject = AuthRouteObject<MetaMenu>;

export const routers: MetaMenuAuthRouteObject[] = [
  { path: '/', element: <TimelineSection /> },
  { path: '*', element: <NotFound /> },
];
