import { combineReducers } from '@reduxjs/toolkit';

import { appReducer } from './app';
import { loadingReducer } from './loading';
import { modalReducer } from './modal';
import { projectReducer } from './project';
import { timekeepingReducer } from './timekeeping';

const mainReducer = combineReducers({
  app: appReducer,
  loading: loadingReducer,
  modal: modalReducer,
  project: projectReducer,
  timekeeping: timekeepingReducer
});

const rootReducers = (state: any, action: any) => {
  // reset store if logout
  if (action.type === 'app/logout') {
    state = {
      app: {
        language: state.app.language
      }
    }
  }

  return mainReducer(state, action);
}

export default rootReducers;
