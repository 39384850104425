import HttpClient from "./HttpClient";
import { RequestOptions } from "./types";
import { getEnvVars } from "@/environment";

const { apiUrl } = getEnvVars();

class UserController {
  public Get = {
    fetchCurrentUser: (options?: RequestOptions) => {
      return HttpClient.get(`${apiUrl}/api/employee/me`, options);
    },
    fetchCurrentUserStatus: (options?: RequestOptions) => {
      return HttpClient.get(`${apiUrl}/api/employee/status`, options);
    },
    fetchSettings: (options?: RequestOptions) => {
      return HttpClient.get(`${apiUrl}/api/company`, options);
    }
  };
}

export const UserService = new UserController();