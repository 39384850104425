import { catchError, concat, filter, map, switchMap } from 'rxjs';

import { timekeepingActions } from './timekeepingSlice';
import { startLoading, stopLoading } from '../loading';
import { RootEpic } from '../types';
import { CheckInMealPayload, FaceCheckService } from '@/services/FaceCheckService';
import { RequestOptions } from '@/services/types';
import Utils from '@/utils/Utils';

const getTeamsOfOperatorRequest$: RootEpic = action$ => {
  return action$.pipe(
    filter(timekeepingActions.getTeamsOfOperatorRequest.match),
    switchMap(action => {
      const { operatorId, accessToken } = action.payload;
      const options: RequestOptions = {};
      if (accessToken) {
        options.headers = {
          Authorization: `Bearer ${accessToken}`,
        };
      }
      return concat(
        [startLoading({ key: 'getTeams' })],
        FaceCheckService.Get.fetchTeamsOfOperator(operatorId, options).pipe(
          map(teams => timekeepingActions.setTeams(teams)),
          catchError(error => {
            Utils.errorHandling(error);
            return [timekeepingActions.setTeams([])];
          }),
        ),
        [stopLoading({ key: 'getTeams' })],
      );
    }),
  );
};

const getTimeKeepingOfTeamRequest$: RootEpic = action$ => {
  return action$.pipe(
    filter(timekeepingActions.getTimeKeepingOfTeamRequest.match),
    switchMap(action => {
      const { team_id, working_day, accessToken } = action.payload;
      const options: RequestOptions = {};
      if (accessToken) {
        options.headers = {
          Authorization: `Bearer ${accessToken}`,
        };
      }
      return concat(
        [startLoading({ key: 'getTimeKeepingOfTeamRequest' })],
        FaceCheckService.Get.fetchTimeKeepingOfTeam({ team_id, working_day }, options).pipe(
          map(timekeepingActions.setCheckInData),
          catchError(error => {
            Utils.errorHandling(error);
            return [timekeepingActions.setCheckInData([])];
          }),
        ),
        [stopLoading({ key: 'getTimeKeepingOfTeamRequest' })],
      );
    }),
  );
};

const approvedHoursWorkingRequest$: RootEpic = action$ => {
  return action$.pipe(
    filter(timekeepingActions.approvedHoursWorkingRequest.match),
    switchMap(action => {
      const { approvedData, accessToken } = action.payload;
      const { working_day, team_id, face_Identity_Id, meal } = approvedData;
      const mealInfo = { meal1: meal > 0 ? 1 : 0, meal2: meal === 2 ? 1 : 0 };
      const mealData: CheckInMealPayload = {
        working_Day: working_day,
        mealList: [{ face_Identity_Id, information: JSON.stringify(mealInfo) }],
      };
      const options: RequestOptions = {};
      if (accessToken) {
        options.headers = {
          Authorization: `Bearer ${accessToken}`,
        };
      }
      return concat(
        [startLoading({ key: 'approvedHoursWorkingRequest' })],
        FaceCheckService.Post.checkInMeal(mealData, options).pipe(
          switchMap((mealResponse) => {
            // todo:
            console.log('Lưu cơm thành công: ', mealResponse);
            return [];
          }),
          catchError(error => {
            console.log('Lưu cơm thất bại: ', error);
            return [];
          }),
        ),
        FaceCheckService.Post.approvedHoursWorking(approvedData, options).pipe(
          switchMap(() => {
            Utils.successNotification();
            return [timekeepingActions.getTimeKeepingOfTeamRequest({ team_id, working_day, accessToken })];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [];
          }),
        ),
        [stopLoading({ key: 'approvedHoursWorkingRequest' })],
      );
    }),
  );
};

const getCheckInPhoto$: RootEpic = action$ => {
  return action$.pipe(
    filter(timekeepingActions.getCheckInPhoto.match),
    switchMap(action => {
      const { checkInId, accessToken } = action.payload;
      const options: RequestOptions = {};
      if (accessToken) {
        options.headers = {
          Authorization: `Bearer ${accessToken}`,
        };
      }
      return concat(
        [startLoading({ key: 'getCheckInPhoto' })],
        FaceCheckService.Get.fetchCheckInPhoto(checkInId, options).pipe(
          switchMap(blob => {
            const objectUrl = URL.createObjectURL(blob);
            return [timekeepingActions.setCheckInPhoto(objectUrl)];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [timekeepingActions.setCheckInPhoto(undefined)];
          }),
        ),
        [stopLoading({ key: 'getCheckInPhoto' })],
      );
    }),
  );
};

export const timekeepingEpics = [
  getTeamsOfOperatorRequest$,
  getTimeKeepingOfTeamRequest$,
  approvedHoursWorkingRequest$,
  getCheckInPhoto$,
];
