import * as React from 'react';

import { Provider as StoreProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import 'dhtmlx-gantt/codebase/dhtmlxgantt.css';
// import 'dhtmlx-gantt/codebase/skins/dhtmlxgantt_material.css';
import { AuthProvider } from './components';
import { AppRouter } from './routes';
import { injectStore } from './services/HttpClient';
import { persistor, store } from './store';
import ThemeCustomization from './themes';
import './translations';

injectStore(store);

function App() {
  return (
    <StoreProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeCustomization>
          <AuthProvider>
            <AppRouter />
          </AuthProvider>
        </ThemeCustomization>
      </PersistGate>
    </StoreProvider>
  );
}

export default App;
