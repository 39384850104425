const apiUrl = `https://checkin.tingconnect.com`;

interface Enviroment {
  dev: EnvVar;
  prod: EnvVar;
}

interface EnvVar {
  apiUrl: string;
  oAuthConfig: {
    issuer: string;
    clientId: string;
    scope: string;
    clientSecret?: string;
  };
  localization: {
    defaultResourceName: string;
  };
}

const ENV: Enviroment = {
  dev: {
    apiUrl: apiUrl,
    oAuthConfig: {
      issuer: apiUrl,
      clientId: 'Admin',
      scope: 'offline_access API',
    },
    localization: {
      defaultResourceName: 'hicas',
    },
  },
  prod: {
    apiUrl: 'https://checkin.tingconnect.com',
    oAuthConfig: {
      issuer: apiUrl,
      clientId: 'Admin',
      scope: 'offline_access API',
      clientSecret: 'hicas_secret',
    },
    localization: {
      defaultResourceName: 'hicas',
    },
  },
};

export const getEnvVars = () => {
  // eslint-disable-next-line no-undef
  return process.env.NODE_ENV === 'development' ? ENV.dev : ENV.prod;
};
