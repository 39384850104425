import { AnyAction, configureStore } from '@reduxjs/toolkit';
import { createEpicMiddleware } from 'redux-observable';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import rootEpics from './epics';
import rootReducers from './reducers';
import { RootState } from './types';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['app'],
};

const ignoredSerializableCheck = [
  'app/loginRequest',
  'app/logout',
  'app/loginCallback',
  'timekeeping/getTimeKeepingOfTeamRequest'
];

const persistedReducer = persistReducer(persistConfig, rootReducers);

const epicMiddleware = createEpicMiddleware<AnyAction, AnyAction, RootState>();

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) => {
    const middlewares = getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, ...ignoredSerializableCheck],
      },
    }).concat(epicMiddleware);

    return middlewares;
  },
});

epicMiddleware.run(rootEpics);

export const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
export type AppStore = typeof store;
