import common from './common.json';
import leftSider from './leftSider.json';
import login from './login.json';
import projects from './projects.json';
import welcome from './welcome.json';

// eslint-disable-next-line
export default {
  common,
  welcome,
  login,
  leftSider,
  projects,
};
