import {
    DatabaseOutlined,
    DeleteOutlined,
    FileDoneOutlined,
    HomeOutlined,
    LineChartOutlined
} from '@ant-design/icons';
import { Layout, Menu, SiderProps } from 'antd';
import { ItemType, MenuItemType } from 'antd/es/menu/hooks/useItems';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import styles from './LeftSider.module.less';
import { colors } from '@/common/colors';

const { Sider } = Layout;

export const LeftSider = (props: SiderProps) => {
    const { collapsed, ...rest } = props;

    const { id: projectId } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation(['leftSider']);

    const itemsTopMenu: ItemType<MenuItemType>[] = [
        {
            label: t('topMenu.home.tile'),
            icon: <FileDoneOutlined />,
            key: `/projects`,
            children: [
                {
                    label: t('topMenu.home.mainMaterial'),
                    key: `/projects/${projectId}/main-material`,
                },
                {
                    label: t('topMenu.home.auxiliaryMaterial'),
                    key: `/projects/${projectId}/auxiliary-material`,
                },
                {
                    label: t('topMenu.home.machinery'),
                    key: `/projects/${projectId}/machinery`,
                },
                {
                    label: t('topMenu.home.transferMaterial'),
                    key: `/projects/${projectId}/transfer-material`,
                },
                {
                    label: t('topMenu.home.inventoryReport'),
                    key: `/projects/${projectId}/inventory-report`,
                },
                {
                    label: t('topMenu.home.statisticalMaterialMachinery'),
                    key: `/projects/${projectId}/machinery-and-equipment-statistics`,
                },
            ]
        },
        {
            label: t('topMenu.projectManagement.title'),
            icon: <HomeOutlined />,
            key: `/project-management`,
            children: [
                {
                    label: t('topMenu.projectManagement.projectFiles'),
                    key: `/project-management/${projectId}/files`
                }
            ]
        },
        {
            label: t('topMenu.kpiSalary.title'),
            icon: <LineChartOutlined />,
            key: `/kpi`,
            children: [
                {
                    label: t('topMenu.kpiSalary.biddingKpi'),
                    key: `/kpi/${projectId}/bidding`
                }
            ]
        }
    ]

    const itemsBottomMenu: ItemType<MenuItemType>[] = [
        {
            label: t('bottomMenu.deletedFiles'),
            icon: <DeleteOutlined />,
            key: `/deleted-files/${projectId}`,
        },
        {
            label: t('bottomMenu.data'),
            icon: <DatabaseOutlined />,
            key: `/data/${projectId}`,
        }
    ]

    return (
        <Sider
            breakpoint="lg"
            collapsedWidth="0"
            trigger={null}
            collapsible
            collapsed={collapsed}
            width={240}
            className={styles.main}
            style={{ backgroundColor: colors.colorSider }}
            {...rest}>
            <Menu
                mode="inline"
                onClick={({ key }) => {
                    navigate(key);
                }}
                defaultSelectedKeys={[window.location.pathname]}
                defaultOpenKeys={[window.location.pathname]}
                items={itemsTopMenu}
                className={styles.top_menu}
                style={{ backgroundColor: colors.colorSider }}
            />
            <Menu
                mode="inline"
                onClick={({ key }) => {
                    navigate(key);
                }}
                defaultSelectedKeys={[window.location.pathname]}
                defaultOpenKeys={[window.location.pathname]}
                items={itemsBottomMenu}
                className={styles.bottom_menu}
                style={{ backgroundColor: colors.colorSider }}
            />
        </Sider>
    )
}
