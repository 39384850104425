import { Project } from "@/common/define";

export const ProjectList: Project[] = [
  {
    id: '1',
    title: 'Nhà ở chị Uyển',
    address: 'Phạm Hùng, Mỹ Đình, Nam Từ Liêm, HN',
  },
  {
    id: '2',
    title: 'Nhà ở anh Nam',
    address: 'Phạm Hùng, Mỹ Đình, Nam Từ Liêm, HN',
  },
  {
    id: '3',
    title: 'Chung cư mini CG',
    address: 'Phạm Hùng, Mỹ Đình, Nam Từ Liêm, HN',
  },
  {
    id: '4',
    title: 'Nhà ở chị Liên',
    address: 'Phạm Hùng, Mỹ Đình, Nam Từ Liêm, HN',
  },
  {
    id: '5',
    title: 'Nhà ở chị Uyển 1',
    address: 'Phạm Hùng, Mỹ Đình, Nam Từ Liêm, HN',
  },
  {
    id: '6',
    title: 'Nhà ở anh Nam 1',
    address: 'Phạm Hùng, Mỹ Đình, Nam Từ Liêm, HN',
  },
  {
    id: '7',
    title: 'Chung cư mini CG 1',
    address: 'Phạm Hùng, Mỹ Đình, Nam Từ Liêm, HN',
  },
  {
    id: '8',
    title: 'Nhà ở chị Liên 1',
    address: 'Phạm Hùng, Mỹ Đình, Nam Từ Liêm, HN',
  },
  {
    id: '9',
    title: 'Nhà ở chị Uyển 2',
    address: 'Phạm Hùng, Mỹ Đình, Nam Từ Liêm, HN',
  },
  {
    id: '10',
    title: 'Nhà ở anh Nam 2',
    address: 'Phạm Hùng, Mỹ Đình, Nam Từ Liêm, HN',
  },
  {
    id: '11',
    title: 'Chung cư mini CG 2',
    address: 'Phạm Hùng, Mỹ Đình, Nam Từ Liêm, HN',
  },
  {
    id: '12',
    title: 'Nhà ở chị Liên 2',
    address: 'Phạm Hùng, Mỹ Đình, Nam Từ Liêm, HN',
  },
]