import { notification } from 'antd';
import i18next from 'i18next';

export default class Utils {
  static deepClone(obj: any) {
    return JSON.parse(JSON.stringify(obj));
  }

  static getPersistAppState() {
    const persistState = localStorage.getItem('persist:root');
    const rootState = persistState ? JSON.parse(persistState) : {};
    /* prettier-ignore */
    const persistAppState: any = rootState['app'] ? JSON.parse(rootState['app']) : {};
    return persistAppState;
  }

  static convertISODateToLocalTime(isoDateString: string) {
    const date = new Date(isoDateString);
    const timestampWithOffset = date.getTime();
    const offset = date.getTimezoneOffset() * 60 * 1000;
    const timestampWithoutOffset = timestampWithOffset - offset;
    const dateWithoutOffset = new Date(timestampWithoutOffset);
    return dateWithoutOffset;
  }

  static errorHandling(error: any) {
    console.log(JSON.stringify(error));
    if (error.errorCode && error.msg) {
      notification.error({
        message: i18next.t('notification'),
        description: i18next.t(error.msg),
      });
      return;
    }
    if (error.status === 401) {
      notification.error({
        message: i18next.t('notification'),
        description: i18next.t('Token expired'),
      });
      return;
    }
    if (error.status === 404) {
      notification.error({
        message: i18next.t('notification'),
        description: i18next.t(error.message || 'Not Found'),
      });
      return;
    }
    if (error?.response?.error_description) {
      notification.error({
        message: i18next.t('notification'),
        description: i18next.t(error.response.error_description),
      });
      return;
    }
    // TODO:
    notification.error({
      message: i18next.t('notification'),
      description: i18next.t('An error occurred while processing your request'),
    });
  }

  static successNotification(message?: string) {
    notification.success({
      message: i18next.t('notification'),
      description: i18next.t(message || 'Saved successfully'),
    });
  }
}
