import React, { useState } from 'react';

import {
  MenuFoldOutlined,
  MenuUnfoldOutlined
} from '@ant-design/icons';
import { Button, Layout, theme } from 'antd';
import { Outlet, useParams } from 'react-router-dom';

import styles from './AuthLayout.module.less';
import { LayoutHeader } from './Header/LayoutHeader';
import { LeftSider } from './Sider/LeftSider';
import { RequireAuth } from '../RequireAuth';
import { MetaMenuAuthRouteObject } from '@/routes';

const { Header, Content } = Layout;

interface LayoutProps {
  // default routers
  routers?: MetaMenuAuthRouteObject[];
  // menu routers
  authRouters?: MetaMenuAuthRouteObject[];
}

export const AuthLayout = (props: LayoutProps) => {
  const { routers, authRouters } = props;
  // you can use this to generate your menus
  console.log('Layout: ', routers, authRouters);

  const { id: projectId } = useParams();

  const [leftSiderVisable, setLeftSiderVisable] = useState(false);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <RequireAuth>
      <Layout className={styles.mainContainer}>
        <LayoutHeader />
        <Layout>
          {projectId && (
            <LeftSider collapsed={leftSiderVisable} />
          )}
          <Layout>
            <Header style={{ padding: 0, background: colorBgContainer }}>
              {projectId && (
                <Button
                  type="text"
                  icon={leftSiderVisable ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                  onClick={() => setLeftSiderVisable(!leftSiderVisable)}
                  style={{
                    fontSize: '16px',
                    width: 64,
                    height: 64,
                  }}
                />
              )}
            </Header>
            <Content className={styles.contentContainer}>
              <Outlet />
            </Content>
          </Layout>
        </Layout>
      </Layout>
    </RequireAuth>
  );
};
