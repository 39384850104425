import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { LoginInput } from '@/common/define';
import { setToken } from '@/services/HttpClient';

interface AppState {
  language: string;
  auth?: any;
  me?: any;
}

const initialState: AppState = {
  language: 'vi',
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    ping: (state) => {},
    // prettier-ignore
    loginRequest: (state, action: PayloadAction<{ input: LoginInput; callback: VoidFunction }>) => {},
    loginSuccess: (state, action) => {
      const { loginResponse, loginData } = action.payload;
      const { info, token } = loginResponse;
      setToken(token);
      const auth = {
        user: info,
        token,
        loginData,
      };
      if (!loginData.remember) {
        delete auth.loginData;
      }
      state.auth = auth;
    },
    loginCallback: (state, action) => {
      const { callback } = action.payload;
      callback();
    },
    logout: (state, action: PayloadAction<{ callback?: VoidFunction }>) => {
      const { callback } = action.payload;
      if (callback) {
        callback();
      }
    },
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
    getCurrentUserRequest: (state) => {},
    setCurrentUser: (state, action) => {
      state.me = action.payload;
    },
    getCurrentUserStatusRequest: (state) => {},
    getSettings: (state) => {}
  },
});

export const appActions = appSlice.actions;
export const appReducer = appSlice.reducer;
