import React from 'react';

import { UserOutlined } from '@ant-design/icons';
import { Avatar, Badge, Layout, Select, Tooltip, Typography } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

import styles from './LayoutHeader.module.less';
import iconBell from '@/image/icon/icon-bell.svg';
import LogoNVH from '@/image/LogoNVH.svg';
import { useAppSelector } from '@/store/hooks';
import { getProjectList } from '@/store/project';

const { Header } = Layout;

export const LayoutHeader = () => {
  const { id: projectId } = useParams();

  const navigate = useNavigate();

  const projectList = useAppSelector(getProjectList());
  const projectSelectList = projectList.map(project => {
    return ({
      value: project.id,
      label: project.title,
    })
  })

  const onChange = (value: string) => {
    console.log(`selected ${value}`);
    navigate(`/projects/${value}/main-material`);
  };

  const onSearch = (value: string) => {
    console.log('search:', value);
  };

  const filterOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <Header className={styles.headerContainer}>
      <div className={styles.headerLeft}>
        <div className={styles.logoContainer}>
          <img src={LogoNVH} alt="Logo" className={styles.logo} />
        </div>
        {projectId && (
          <Select
            value={projectId}
            showSearch
            placeholder="Select a project"
            optionFilterProp="children"
            onChange={onChange}
            onSearch={onSearch}
            filterOption={filterOption}
            options={projectSelectList}
            className={styles.projectSelect}
          />
        )}
      </div>
      <div className={styles.headerRight}>
        <Tooltip placement="bottom" title="Notification">
          <div className={styles.notiContainer}>
            <Badge count={99} offset={[10, 10]}>
              <img src={iconBell} alt="bell" className={styles.iconBell} />
            </Badge>
          </div>
        </Tooltip>
        <Tooltip placement="bottom" title="Profile">
          <div className={styles.avatarContainer}>
            <Avatar icon={<UserOutlined />} />
            <Typography.Text className={styles.userNameText}>Admin</Typography.Text>
          </div>
        </Tooltip>
      </div>
    </Header>
  )
}
